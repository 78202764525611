import { IsTenantAvailableOutputState, StopEditDtoStopType, UserDtoUserAccountType, AddFileDtoFileStoreType, AddFileDtoFileStoreFileType, StopEditDtoStopState, InspecEditDtoStatus, InspecEditDtoStepAction, RateEditDtoRateType, SendEmailDtoNoteType } from '@shared/service-proxies/service-proxies';

export class AppTenantAvailabilityState {
	static Available: number = IsTenantAvailableOutputState._1;
	static InActive: number = IsTenantAvailableOutputState._2;
	static NotFound: number = IsTenantAvailableOutputState._3;
}

export class StopfreightTypes {
	static SeaFreight: number = StopEditDtoStopType._0;
	static AirFreight: number = StopEditDtoStopType._1;
	static SeaAndAirFreight: number = StopEditDtoStopType._2;
	static SAPSReleaseSeaFreight: number = StopEditDtoStopType._3;
	static SAPSReleaseAirFreight: number = StopEditDtoStopType._4;
}
export class StopTypes {
	static SeaFreight: number = StopEditDtoStopType._0;
	static AirFreight: number = StopEditDtoStopType._1;
	static SeaAndAirFreight: number = StopEditDtoStopType._2;
	static SAPSReleaseSeaFreight: number = StopEditDtoStopType._3;
	static SAPSReleaseAirFreight: number = StopEditDtoStopType._4;
}
export class StopFreightType {
	name: string;
	id: number;
}

export class BaseLookupDto {
	name: string;
	id: number;
	disabled: boolean;
}

export class UserAccountType {
	static System: number = UserDtoUserAccountType._0;
	static Inspector: number = UserDtoUserAccountType._1;
	static Company: number = UserDtoUserAccountType._2;
	static Saps: number = UserDtoUserAccountType._3;
}

export class UseraccountType {
	name: string;
	id: number;
}
export class FromStopToInvoice {
	InvoiceId: number;
	StopId: number;
	IsFromStop: boolean;
}
export class NoteType {
	static Note: number = SendEmailDtoNoteType._0;
	static Survey: number = SendEmailDtoNoteType._1;
	static Invoice: number = SendEmailDtoNoteType._2;
	static Finalized: number = SendEmailDtoNoteType._3;
}

export class FileStoreType {
	static InspectionStop: number = AddFileDtoFileStoreType._0;
	static Agent: number = AddFileDtoFileStoreType._1;
	static Tenant: number = AddFileDtoFileStoreType._3;
	static User: number = AddFileDtoFileStoreType._4;
	static SapsBranch: number = AddFileDtoFileStoreType._5;
}

export class FileStoreFileType {
	static Document: number = AddFileDtoFileStoreFileType._0;
	static Image: number = AddFileDtoFileStoreFileType._1;
	static Logo: number = AddFileDtoFileStoreFileType._2;
	static Signature: number = AddFileDtoFileStoreFileType._4;

	//
	static WaterMarkLogo: number = AddFileDtoFileStoreFileType._7;
	static EmailFixedFileAttachment: number = AddFileDtoFileStoreFileType._9;
	static UserSignature: number = AddFileDtoFileStoreFileType._10;
}

export class StopState {
	static Open: number = StopEditDtoStopState._0;
	static InspectionStage: number = StopEditDtoStopState._1;
	static Finalized: number = StopEditDtoStopState._2;
	static PendingConfirmation: number = StopEditDtoStopState._3;
	static InspectionComplete: number = StopEditDtoStopState._4;
	static Aborted: number = StopEditDtoStopState._5;
	static InspectionAllocated: number = StopEditDtoStopState._6;
	static Cancelled: number = StopEditDtoStopState._7;
	static DocumentsSubmitted: number = StopEditDtoStopState._8;
	static DocumentsRe_Submitted: number = StopEditDtoStopState._9;
	static Awaiting_Officials: number = StopEditDtoStopState._10;
	static Update_Required: number = StopEditDtoStopState._11;
}
export class StepAction {
	static TakePhoto: number = InspecEditDtoStepAction._0;
	static GetCount: number = InspecEditDtoStepAction._1;
	static GetSignature: number = InspecEditDtoStepAction._2;
	static Information: number = InspecEditDtoStepAction._3;
	static Aborted: number = InspecEditDtoStepAction._4;
	static Update: number = InspecEditDtoStepAction._5;
}
export class InspecStatus {
	static Idle: number = InspecEditDtoStatus._0;
	static InProgress: number = InspecEditDtoStatus._1;
	static Completed: number = InspecEditDtoStatus._2;
}
export class RateType {
	static StopRate: number = RateEditDtoRateType._0;
	static AdhocRate: number = RateEditDtoRateType._1;
	static GeneralRate: number = RateEditDtoRateType._2;
}
